<template>
  <!-- 現貨入庫的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="spotWarehousingModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">現貨入庫</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-2" v-if="warehousingData.orderInfo">
            <p class="mb-2">
              <span class="fw-bolder">商品名稱：</span>
              {{ warehousingData.orderInfo.merchandise.name }}
            </p>
            <br />
            <p class="mb-2">
              <span class="fw-bolder">商品樣式：</span>
              {{ warehousingData.orderInfo.merchandise.style }}
            </p>
            <br />
            <p class="mb-2">
              <span class="fw-bolder">顧客名稱：</span>
              {{ warehousingData.orderInfo.buyer.name }}
            </p>
            <br />
            <p class="mb-2">
              <span class="fw-bolder">訂單總數：</span
              >{{ warehousingData.orderInfo.quantity }}，<span class="fw-bolder"
                >未配數：</span
              >{{
                warehousingData.orderInfo.quantity -
                warehousingData.orderInfo.allocatedQuantity
              }}，<span class="fw-bolder">可配數：</span
              >{{
                warehousingData.orderInfo.stock.availableFromStockQuantity
              }}，<span class="fw-bolder">已結單：</span
              >{{ warehousingData.orderInfo.checkOutQuantity }}
            </p>
          </div>
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="StockInQuantity"
              placeholder="入庫數量"
              v-model="stockInQuantity"
            />
            <label for="StockInQuantity">入庫數量</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
            @click="closeModal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="createStock(true)"
          >
            入庫並配單
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="createStock(false)"
          >
            入庫
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SweetAlert } from '../../methods/sweetAlert/SweetAlert'
export default {
  props: {
    warehousingData: {
      type: Object,
      default: {
        warehousingModal: false,
        orderInfo: null,
      },
    }
  },
  data() {
    return {
      // modal
      spotWarehousingModal: {},
      // data
      stockInQuantity: 0,
    }
  },
  mounted() {
    this.createModals(['spotWarehousingModal'])
  },
  watch: {
    'warehousingData.warehousingModal'(val) {
      if (val) {
        this.stockInQuantity = 0
        this.spotWarehousingModal.show()
      }
    },
  },
  methods: {
    // 建立庫存
    createStock(allocate) {
      if (!this.stockInQuantity) return SweetAlert('other', '您尚未輸入想入庫的數量')
      this.$methods.switchLoading('show')
      const data = [{
        stock: {
          id: this.warehousingData.orderInfo.stock.id,
          quantity: this.warehousingData.orderInfo.stock.quantity + this.stockInQuantity,
        },
        allocate: allocate
      }]
      console.log(data)
      this.$api.stock.update(data).then(res => {
        console.log(res)
        if (res.code === '200') {
          this.SweetAlert(res.code, res.message)
          this.$emit('notifyFromSpotWarehousing', {
            reload: true
          })
          this.spotWarehousingModal.hide()
          this.closeModal()
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        if (err.responseJSON) this.sortoutErrorMsg(err.responseJSON)
        this.$methods.switchLoading('hide')
      })
    },
    // 處理錯誤訊息
    sortoutErrorMsg(err) {
      if (err.errorData.length > 0) {
        const obj = {
          icon: 'warning',
          title: {
            show: true,
            value: '錯誤'
          },
          text: {
            show: true,
            value: ''
          },
          timer: 3000,
          showConfirmButton: false
        }
        err.errorData.forEach(item => { obj.text.value = item.message });
        this.SweetAlert('setByMyself', obj)
      } else this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
    },
    closeModal() {
      this.warehousingData.warehousingModal = false
    },
  },
}
</script>