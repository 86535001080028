<template>
  <!-- OK dataTable -->
  <ProgressBar :progressData="progressData"></ProgressBar>
  <div class="tw-container" :class="{ 'p-0': currentPage === 'Orders' }">
    <div class="management-datatable">
      <DataTable
        class="p-datatable-sm"
        :scrollHeight="`${scrollHeight}px`"
        :value="merchOrderList"
        dataKey="id"
        :loading="dataTabelLoading"
        :rowHover="true"
        :paginator="true"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rows="D4Row"
        :rowsPerPageOptions="[20, 50, 100]"
        :scrollable="true"
        currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
        v-model:filters="searchGlobal"
        filterDisplay="menue"
        stateStorage="local"
        stateKey="dt-state-merchOrder-local"
        sortField="createTime"
        :sortOrder="-1"
        v-model:selection="selectItems"
      >
        <template #header>
          <div class="d-flex align-items-center">
            <FieldFilter
              whitchOneTable="merchOrder"
              :D4FieldFilter="fieldFilter"
            ></FieldFilter>
            <div class="w-100">
              <p>表格搜尋</p>
              <input
                class="form-control me-2"
                v-model="searchGlobal['global'].value"
                placeholder="表格搜尋: 商品名稱，商品款式，顧客姓名，供應商，金額，利潤，配單，結單，收款，寄出，建立時間"
                style="max-width: 99%"
              />
            </div>
          </div>
        </template>
        <!-- 選取 -->
        <Column
          v-if="fieldFilter[0].selected"
          selectionMode="multiple"
          style="max-width: 50px"
        ></Column>
        <!-- 圖示 -->
        <Column
          v-if="fieldFilter[1].selected"
          field=""
          header="圖示"
          style="min-width: 90px; max-width: 90px"
        >
          <template #body="{ data }">
            <!-- 商品首圖 -->
            <div class="d-inline-block">
              <Image
                class="table-img"
                :imageHash="data.merchandise.frontImage"
                size="s"
                v-if="data.merchandise.frontImage"
                :alt="data.merchandise.frontImage"
              ></Image>
              <img
                class="table-img"
                v-else
                src="@/assets/other-images/noImg.png"
                alt=""
              />
            </div>
            <!-- 訂單來源 -->
            <div class="d-inline-block">
              <img
                class="icon"
                v-if="data.creationType === 1"
                title="訂單來自於賣家"
                src="@/assets/icon/seller.png"
              />
              <img
                class="icon"
                v-else-if="data.creationType === 2"
                title="訂單來自於顧客"
                src="@/assets/icon/customer.png"
              />
              <img
                class="icon"
                v-else-if="data.creationType === 3"
                title="訂單來自於FB"
                src="@/assets/icon/facebook-3.png"
              />
              <img
                class="icon"
                v-else-if="data.creationType === 4"
                title="訂單來自於賣家使用 FB 留言"
                src="@/assets/icon/user-free-icon-fontfb.png"
              />
              <img
                class="icon"
                v-else-if="data.creationType === 5"
                title="訂單來自於顧客 LINE 群組下單"
                src="@/assets/icon/user-free-icon-fontLINE.png"
              />
            </div>
          </template>
        </Column>
        <!-- 商品 -->
        <Column
          v-if="fieldFilter[2].selected"
          field="merchandise.name"
          header="商品"
          sortable
          style="min-width: 200px; max-width: 200px"
        >
          <template #body="{ data }">
            <div title="訂單編號">
              <img class="icon" src="@/assets/icon/bookmark.png" alt="" />
              <span>：{{ data.id }}</span>
            </div>
            <router-link
              :title="`商品備註: ${data.merchandise.note}`"
              :to="`/seller/store/${storeId}/merchandiseInfo/detail?merchandiseId=${data.merchandise.id}`"
              class="fw-bolder text-break"
              :class="{ 'text-danger': data.merchandise.deleted }"
              v-if="currentPage !== 'Orders'"
              target="_blank"
              >{{ data.merchandise.name }}</router-link
            >
            <p class="fw-bolder text-break" v-else>
              {{ data.merchandise.name }}
            </p>
            <p
              class="pointer ellipsis2 text-secondary can-click"
              @click="showModal('orderNote', data.note)"
              v-if="data.note"
            >
              {{ data.note }}
            </p>
          </template>
        </Column>
        <!-- 款式 -->
        <Column
          v-if="fieldFilter[6].selected"
          field="merchandise.style"
          header="款式"
          sortable
          style="min-width: 150px"
        >
          <template #body="{ data }">
            {{ data.merchandise.style }}
          </template>
        </Column>
        <!-- 明細 -->
        <Column
          v-if="fieldFilter[5].selected"
          field="soldOutQuantity"
          header="明細"
          sortable
          style="min-width: 100px"
        >
          <template #body="{ data }">
            <!-- 明細 -->
            <div class="mb-1">
              <span v-if="data.soldOutQuantity > 0">
                {{ data.quantity }}X{{ data.price }}
              </span>
              <span
                class="pointer can-click"
                v-else
                @click="showModal('editOrder', data)"
              >
                {{ data.quantity }}X{{ data.price }}
              </span>
            </div>
            <!-- 斷貨 -->
            <div class="text-danger" v-if="data.soldOutQuantity > 0">
              <p class="text-break">斷貨數: {{ data.soldOutQuantity }}</p>
              <p class="text-break">
                原訂單: {{ data.quantity + data.soldOutQuantity }}
              </p>
            </div>
          </template>
        </Column>
        <!-- 顧客姓名 -->
        <Column
          v-if="fieldFilter[3].selected"
          field="buyer.name"
          header="顧客姓名"
          style="min-width: 200px; max-width: 200px"
          sortable
        >
          <template #body="{ data }">
            <p class="fw-bolder text-break">
              <p>顧客ID: {{ data.buyer.id }}</p>
              <router-link
                v-if="currentPage !== 'P_MerchOrder'"
                :to="`/seller/store/${storeId}/participant/${data.buyer.id}/merchOrder`"
                class="text-primary"
                target="_blank"
                >{{ data.buyer.name }}</router-link
              >
              <span v-else>{{ data.buyer.name }}</span>
              <span class="text-secondary" v-if="data.buyer.nickName"
                >｜ {{ data.buyer.nickName }}</span
              >
            </p>
            <!-- line 名稱 -->
            <div v-if="data.buyer.lineName !== null">
              <img
                class="icon"
                title="顧客的 Line 名稱"
                src="@/assets/icon/line-icon.png"
                alt=""
              />
              <img
                  class="icon"
                  title="顧客有綁定Line Notify"
                  src="@/assets/icon/linenotify.png"
                  alt=""
                  v-if="data.buyer.buyerHasNotify"
                />
              <span>&ensp;{{ data.buyer.lineName }}</span>
            </div>
            <!-- fb 名稱 -->
            <div v-if="data.buyer.fbName">
              <img class="icon" src="@/assets/icon/facebook-2.jpg" title="顧客的 Facebook 名稱" alt="" />
              <span>&ensp;{{ data.buyer.fbName }}</span>
            </div>
            <!-- VIP -->
            <div class="p-0" title="顧客的 VIP" v-if="data.buyer.storeVipName">
              <img class="icon" src="@/assets/icon/crown1.png" />
              <span>&ensp;{{ data.buyer.storeVipName }}</span>
            </div>
            <!-- 群組 -->
            <div class="p-0" title="顧客的群組" v-if="data.buyer.customerGroupName">
              <img class="icon" src="@/assets/icon/users.png" />
              <span>&ensp;{{ data.buyer.customerGroupName }}</span>
            </div>
          </template>
        </Column>
        <!-- 操作 -->
        <Column
          v-if="fieldFilter[4].selected"
          field=""
          header="操作"
          style="min-width: 50px"
        >
          <template #body="{ data }">
            <div class="dropdown dropend pointer">
              <img
                src="@/assets/icon/settings.png"
                alt=""
                id="Setting"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style="width: 25px; height: 25px"
              />
              <ul class="dropdown-menu" aria-labelledby="Setting">
                <li
                  v-if="
                    data.soldOutQuantity > 0 && $route.name === 'P_MerchOrder'
                  "
                >
                  <router-link
                    class="dropdown-item"
                    :to="`/seller/store/${storeId}/participant/${$route.params.participantId}/bill?search=trace&merchOrderId=${data.id}`"
                    >追查帳單</router-link
                  >
                </li>
                <li>
                  <button
                    class="dropdown-item"
                    @click="showModal('warehousing', data)"
                  >
                    現貨入庫
                  </button>
                </li>
                <li>
                  <router-link
                    :to="`/seller/store/${storeId}/checkoutOrder/${data.buyer.id}?status=preCheckout`"
                    class="dropdown-item"
                    >結單</router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="`/seller/store/${storeId}/checkoutOrder/${data.buyer.id}?status=allocatedCheckout`"
                    class="dropdown-item"
                    >已配商品結單</router-link
                  >
                </li>
                <li>
                  <button class="dropdown-item" @click="showModal('del', data)">
                    刪除
                  </button>
                </li>
              </ul>
            </div>
          </template>
        </Column>
        <!-- 金額 -->
        <Column
          v-if="fieldFilter[7].selected"
          field="totalPrice"
          header="金額"
          sortable
          style="min-width: 80px"
        >
          <template #body="{ data }">
            <p>{{ data.totalPrice }}</p>
          </template>
        </Column>
        <!-- 配單 -->
        <Column
          v-if="fieldFilter[8].selected"
          field="allocatedQuantity"
          header="配單"
          sortable
          style="min-width: 80px"
        >
          <template #body="{ data }">
            <div
              class="pointer can-click"
              @click="showModal('setAllocatedAmount', data)"
            >
              <p>
                <i
                  class="bi bi-exclamation-lg text-danger me-2"
                  v-if="data.quantity > data.allocatedQuantity && data.allocatedQuantity > 0">
                </i>
                <i
                  class="bi bi-check-lg text-success me-2"
                  v-else-if="data.allocatedQuantity === data.quantity"
                ></i>
                {{ data.allocatedQuantity }}
              </p>
            </div>
          </template>
        </Column>
        <!-- 結單 -->
        <Column
          v-if="fieldFilter[9].selected"
          field="checkOutQuantity"
          header="結單"
          sortable
          style="min-width: 80px"
        >
          <template #body="{ data }">
            <p>
              <i
                class="bi bi-exclamation-lg text-danger me-2"
                v-if="data.quantity > data.checkOutQuantity && data.checkOutQuantity > 0"
              ></i>
              <i
                class="bi bi-check-lg text-success me-2"
                v-else-if="data.checkOutQuantity === data.quantity"
              ></i>
              {{ data.checkOutQuantity }}
            </p>
          </template>
        </Column>
        <!-- 收款 -->
        <Column
          v-if="fieldFilter[10].selected"
          field="paymentQuantity"
          header="收款"
          sortable
          style="min-width: 80px"
        >
          <template #body="{ data }">
            <p>
              <i
                class="bi bi-exclamation-lg text-danger me-2"
                v-if="data.quantity > data.paymentQuantity && data.paymentQuantity > 0"
              ></i>
              <i
                class="bi bi-check-lg text-success me-2"
                v-else-if="data.paymentQuantity === data.quantity"
              ></i>
              {{ data.paymentQuantity }}
            </p>
          </template>
        </Column>
        <!-- 寄出 -->
        <Column
          v-if="fieldFilter[11].selected"
          field="shippingQuantity"
          header="寄出"
          sortable
          style="min-width: 80px"
        >
          <template #body="{ data }">
            <p>
              <i
                class="bi bi-exclamation-lg text-danger me-2"
                v-if="data.quantity > data.shippingQuantity && data.shippingQuantity > 0"
              ></i>
              <i
                class="bi bi-check-lg text-success me-2"
                v-else-if="data.shippingQuantity === data.quantity"
              ></i>
              {{ data.shippingQuantity }}
            </p>
          </template>
        </Column>
        <!-- 供應商 -->
        <Column
          v-if="fieldFilter[12].selected"
          field="merchandise.supplierName"
          header="供應商"
          style="min-width: 100px"
          sortable
        >
          <template #body="{ data }">
            <p>{{ data.merchandise.supplierName }}</p>
          </template>
        </Column>
        <!-- 建立 -->
        <Column
          v-if="fieldFilter[13].selected"
          field="createTime"
          header="時間"
          sortable
          style="min-width: 170px"
          :sortOrder="-1"
        >
          <template #body="{ data }">
            <p>
              {{ data.createTime }}
            </p>
          </template>
        </Column>
        <!-- 利潤 -->
        <Column
          v-if="fieldFilter[14].selected"
          field="profit"
          header="利潤"
          sortable
          style="min-width: 80px"
        >
          <template #body="{ data }">
            <p
              :class="{
                'text-danger': data.profit < 0,
                'text-success': data.profit > 0,
              }"
            >
              {{ data.profit }}
            </p>
          </template>
        </Column>
        <template #empty>
          <p class="text-center fw-bolder text-primary">
            目前尚未有任何資料 0..0
          </p>
        </template>
      </DataTable>
    </div>
  </div>
  <!-- OK 斷貨 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="soldOutModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">斷貨</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <div>
            <p class="fw-bolder alert alert-danger">注意 ! 此步驟無法恢復</p>
            <p class="fw-bolder mb-2">預計將所選擇到的訂單設定為斷貨</p>
            <p class="fw-bolder mb-2">&emsp;。訂單標記成 "斷貨"</p>
            <p class="fw-bolder mb-2">
              &emsp;。訂單數自動降至 "已配單數" 或 "已結單數" 選最高者
            </p>
          </div>
          <p
            class="fw-bolder alert alert-warning"
            v-if="canNotSoldOut.length > 0"
          >
            警告 ! 訂單編號：
            <span class="text-danger">{{ canNotSoldOut.join("、") }}</span>
            為已斷貨過，故無需設定斷貨
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="soldOut">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 配單、全數配單、配單初始化  modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setAllocatedAmountModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title tw-border-start fw-bolder"
            v-if="setAllocatedAmount === '配單 (全數配單)'"
          >
            全數配單
          </h5>
          <h5
            class="modal-title tw-border-start fw-bolder"
            v-else-if="setAllocatedAmount === '配單 (初始化)'"
          >
            配單歸 0
          </h5>
          <h5
            v-else-if="setAllocatedAmount === '配單 (單項)'"
            class="modal-title tw-border-start fw-bolder"
          >
            配單
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <div v-if="setAllocatedAmount === '配單 (全數配單)'">
            <p class="mb-2">
              是否將所選擇到的訂單，配單數量調整至最
              <span class="fw-bolder text-danger">大</span> ?
            </p>
          </div>
          <div v-else-if="setAllocatedAmount === '配單 (初始化)'">
            <p class="mb-2">
              是否將所選擇到的訂單，配單數量調整至
              <span class="fw-bolder text-danger">0</span> ?
            </p>
          </div>
          <div
            v-else-if="
              setAllocatedAmount === '配單 (單項)' && selectItems.length > 0
            "
          >
            <div class="ms-1">
              <p class="mb-2">
                <span class="fw-bolder">商品名稱：</span>
                {{ selectItems[0].merchandise.name }}
              </p>
              <br />
              <p class="mb-2">
                <span class="fw-bolder">商品樣式：</span>
                {{ selectItems[0].merchandise.style }}
              </p>
              <br />
              <p class="mb-2">
                <span class="fw-bolder">顧客名稱：</span>
                {{ selectItems[0].buyer.name }}
              </p>
              <br />
              <p class="mb-2">
                <span class="fw-bolder">
                  訂單總數：{{ selectItems[0].quantity }}，
                </span>
                <span class="fw-bolder">
                  未配數：{{ selectItems[0].quantity - selectItems[0].allocatedQuantity }}，
                </span>
                <span class="fw-bolder">
                  可配數：{{ selectItems[0].availableFromStockQuantity }}，
                </span>
                <span class="fw-bolder">
                  已結單：{{ selectItems[0].checkOutQuantity }}
                </span>
              </p>
            </div>
            <br />
            <div class="form-floating mb-3">
              <input
                onwheel="this.blur()"
                type="number"
                class="form-control"
                id="SetAllocatedQuantity"
                :placeholder="selectItems[0].allocatedQuantity"
                min="0"
                :max="selectItems[0].quantity"
                v-model="aAllocatedQuantity"
                pattern="[0-9]*"
                oninput="this.value=this.value.replace(/\D/g,'')"
              />
              <label for="SetAllocatedQuantity">配單數</label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="setAllocatedQuantity"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 刪除 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="
              modal-title
              border-start
              ps-2
              border-5 border-danger
              text-danger
              fw-bolder
            "
          >
            刪除
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p class="mb-2">
            是否將所選擇到的訂單刪除?
            <span class="fw-bolder text-danger">注意! 此步驟無法恢復</span>
          </p>
          <p class="fw-bolder alert alert-warning" v-if="canNotDel.length > 0">
            警告 ! 訂單編號：
            <span class="text-danger">{{ canNotDel.join("、") }}</span>
            &nbsp;無法刪除 (原因：訂單已被配單或結單)
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="del">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 訂單編輯 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editOrderModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">訂單編輯</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <div class="ms-1" v-if="selectItems.length > 0">
            <p class="mb-2">
              <span class="fw-bolder">商品名稱：</span>
              {{ selectItems[0].merchandise.name }}
            </p>
            <br />
            <p class="mb-2">
              <span class="fw-bolder">商品樣式：</span>
              {{ selectItems[0].merchandise.style }}
            </p>
            <br />
            <p class="mb-2">
              <span class="fw-bolder">顧客名稱：</span>
              {{ selectItems[0].buyer.name }}
            </p>
            <br />
          </div>
          <div>
            <div class="form-floating mb-3">
              <input
                onwheel="this.blur()"
                type="number"
                class="form-control"
                id="OrderQuantity"
                min="0"
                v-model="merchOrderEdit.quantity"
                pattern="[0-9]*"
                oninput="this.value=this.value.replace(/\D/g,'')"
              />
              <label for="OrderQuantity">數量</label>
            </div>
            <div class="form-floating mb-3">
              <input
                onwheel="this.blur()"
                type="number"
                class="form-control"
                id="OrderPrice"
                :placeholder="selectItems.price"
                min="0"
                v-model="merchOrderEdit.price"
                @input="
                  merchOrderEdit.price = $methods.numberToFixed(
                    merchOrderEdit.price
                  )
                "
              />
              <label for="OrderPrice">價格</label>
            </div>
            <div class="form-floating mb-3">
              <input
                onwheel="this.blur()"
                type="number"
                class="form-control"
                id="OrderTotalPrice"
                :value="merchOrderEdit.totalPrice"
                disabled
              />
              <label for="OrderTotalPrice">總價</label>
            </div>
            <div class="form-floating mb-3">
              <textarea
                class="form-control"
                id="OrderNote"
                placeholder="輸入訂單備註於此"
                maxlength="300"
                style="height: 100px"
                rows="5"
                v-model="merchOrderEdit.note"
              />
              <label for="OrderNote">備註(300字)</label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateOrder"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 匯出 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="excelModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">匯出 Excel</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p>是否將所選擇到的訂單匯出成 Excel 格式 ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="exportExcel"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 表格可點選區介紹 modal -->
  <div
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="introductionModal"
  >
    <div class="modal-dialog" :style="scrollWidth">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">表格可點選區域</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <img
              src="@/assets/images/table-introduction/merchOrder.jpg"
              style="max-width: 100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 時間區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_TimeSwitch"
                v-model="advancedSearchData.timeRange.switch"
              />
              <label class="form-check-label" for="AS_TimeSwitch"
                >時間區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.timeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_StartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.timeRange.startTime"
                  />
                  <label for="AS_StartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_EndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.timeRange.endTime"
                  />
                  <label for="AS_EndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 商品收單區間 -->
          <div class="mb-3" v-if="currentPage !== 'Orders'">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_TimeSwitchForDL"
                v-model="advancedSearchData.DLtimeRange.switch"
              />
              <label class="form-check-label" for="AS_TimeSwitchForDL"
                >商品收單區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.DLtimeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_StartTimeForDL"
                    placeholder="起始時間"
                    v-model="advancedSearchData.DLtimeRange.startTime"
                  />
                  <label for="AS_StartTimeForDL" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_EndTimeForDL"
                    placeholder="結束時間"
                    v-model="advancedSearchData.DLtimeRange.endTime"
                  />
                  <label for="AS_EndTimeForDL" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 商品名稱 -->
          <div class="form-floating mb-3" v-if="currentPage !== 'Orders'">
            <input
              type="text"
              class="form-control"
              id="AS_Name"
              maxlength="100"
              placeholder="商品名稱(有包含即可)(100字)"
              v-model="advancedSearchData.merchandiseName"
            />
            <label for="AS_Name">商品名稱(有包含即可)(100字)</label>
          </div>
          <!-- 供應商 -->
          <div class="mb-3" v-if="currentPage !== 'Orders'">
            <div class="form-floating" v-if="!suppliers.loading">
              <select
                class="form-select"
                id="AS_Supplier"
                v-model="advancedSearchData.supplier"
              >
                <option :value="null">不選擇</option>
                <option
                  :value="data"
                  v-for="data in suppliers.data"
                  :key="data.id"
                >
                  {{ data.name }}
                </option>
              </select>
              <label for="AS_Supplier">供應商</label>
            </div>
            <AreaLoading v-else></AreaLoading>
          </div>
          <!-- 款式搜尋 -->
          <div class="form-floating p-0 mb-3">
            <input
              type="text"
              class="form-control"
              id="SerchStyles"
              placeholder="輸入款式"
              v-model="advancedSearchData.stylesName"
            />
            <label for="SerchStyles">輸入款式(EX: 一個紅色XL)</label>
          </div>
          <!-- 篩選條件 -->
          <div class="border mb-3 p-3">
            <p class="tw-border-start fw-bolder">篩選條件</p>
            <hr />
            <ul class="list">
              <li
                class="row border-bottom"
                v-for="data in singleSelectsArray"
                :key="data.id"
              >
                <template
                  v-if="
                    (currentPage === 'P_MerchOrder' &&
                      data.id !== 'buyerStatus') ||
                    currentPage === 'MerchOrder' ||
                    currentPage === 'Orders'
                  "
                >
                  <div class="col-3 my-2">{{ data.key }}</div>
                  <div class="col-9">
                    <div
                      class="select-radio my-2"
                      v-for="select in data.value"
                      :key="select.id"
                    >
                      <label>
                        <input
                          type="radio"
                          :name="data.id"
                          :value="select.value"
                          v-model="advancedSearchData.singleSelect[data.id]"
                        />
                        <span class="radio-style">{{ select.value }}</span>
                      </label>
                    </div>
                    <!-- 輸入選項 (訂單資訊) -->
                    <div>
                      <!-- 輸入文字 -->
                      <div
                        class="form-floating p-0"
                        v-if="
                          (advancedSearchData.singleSelect.orderStatus ==='訂單 ID') &&
                          data.id === 'orderStatus'
                        "
                      >
                        <input
                          type="text"
                          class="form-control"
                          id="SerchText"
                          placeholder="輸入內容"
                          v-model="advancedSearchData.searchOrderText"
                        />
                        <label for="SerchText">輸入內容</label>
                      </div>
                    </div>
                    <!-- 輸入選項 (顧客資訊) -->
                    <div>
                      <!-- 輸入文字 -->
                      <div
                        class="form-floating p-0"
                        v-if="
                          (advancedSearchData.singleSelect.buyerStatus ===
                              '名稱' ||
                            advancedSearchData.singleSelect.buyerStatus ===
                              '暱稱' ||
                            advancedSearchData.singleSelect.buyerStatus ===
                              'FB 名稱' ||
                            advancedSearchData.singleSelect.buyerStatus ===
                              'Line 名稱' ||
                            advancedSearchData.singleSelect.buyerStatus ===
                              '顧客 ID')
                              &&
                          data.id === 'buyerStatus'
                        "
                      >
                        <input
                          type="text"
                          class="form-control"
                          id="SerchText"
                          placeholder="輸入內容"
                          v-model="advancedSearchData.searchBuyerText"
                        />
                        <label for="SerchText">輸入內容</label>
                      </div>
                      <!-- 顧客 > 群組 -->
                      <template
                        v-if="
                          advancedSearchData.singleSelect.buyerStatus ===
                            '顧客群組' && data.id === 'buyerStatus'
                        "
                      >
                        <div
                          class="form-floating"
                          v-if="!storeCustomerGroups.loading"
                        >
                          <select
                            class="form-select"
                            id="SearchGroup"
                            aria-label="SearchGroup"
                            v-model="advancedSearchData.customerGroup"
                          >
                            <option :value="null" disabled>請選擇顧客群組</option>
                            <option
                              v-for="(data, index) in storeCustomerGroups.data"
                              :key="data.id"
                              :value="data.id"
                            >
                              {{ data.name }}
                            </option>
                          </select>
                          <label for="floatingSelect">選擇顧客群組</label>
                        </div>
                        <AreaLoading v-else></AreaLoading>
                      </template>
                      <!-- 顧客 > VIP -->
                      <template
                        v-if="
                          advancedSearchData.singleSelect.buyerStatus ===
                            '顧客 VIP' && data.id === 'buyerStatus'
                        "
                      >
                        <div class="form-floating" v-if="!storeVips.loading">
                          <select
                            class="form-select"
                            id="SearchVip"
                            aria-label="SearchVip"
                            v-model="advancedSearchData.vipId"
                          >
                            <option :value="null" disabled>請選擇顧客 VIP</option>
                            <option
                              v-for="(data, index) in storeVips.data"
                              :key="data.id"
                              :value="data.id"
                            >
                              LV: {{ data.level }}，{{ data.name }}
                            </option>
                          </select>
                          <label for="floatingSelect">選擇顧客 VIP</label>
                        </div>
                        <AreaLoading v-else></AreaLoading>
                      </template>
                      <!-- 顧客 > Notify -->
                      <template       
                        v-if="
                          advancedSearchData.singleSelect.buyerStatus ===
                            '顧客 Notify' && data.id === 'buyerStatus'
                      ">
                        <div class="form-floating">
                          <select
                            class="form-select"
                            id="SearchBuyerNotify"
                            aria-label="SearchBuyerNotify"
                            v-model="advancedSearchData.buyerNotify"
                          >
                            <option :value="null" disabled>請選擇顧客 Notify 狀態</option>
                            <option :value="true">有綁定 Line Notify</option>
                            <option :value="false">沒有綁定 Line Notify</option>
                          </select>
                          <label for="floatingSelect">選擇顧客 Notify 狀態</label>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 顯示備註 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="orderNoteModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">訂單備註
            <a href="https://www.youtube.com/watch?v=OUQpuVpmKw0" target="_blank">
              <img
                class="info-icon"
                src="@/assets/icon/interrogation.png"
              />
            </a>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="text-break" style="line-height: 24px">
            {{ showOrderNote }}
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            了解
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 現貨入庫 modal -->
  <SpotWarehousing
    :warehousingData="warehousingData"
    @notifyFromSpotWarehousing="notifyFromSpotWarehousing"
  ></SpotWarehousing>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import { FilterMatchMode } from 'primevue/api';
// components
import SpotWarehousing from '../../components/modal/SpotWarehousing.vue'
import ProgressBar from '../../components/primeVue/ProgressBar.vue'
import FieldFilter from '../../components/tools/FieldFilter.vue'

export default {
  props: {
    showWhitchModal: {
      type: Object,
      default: {
        soldOut: false,
        setAllocatedAmount: false,
        del: false,
        editOrder: false,
        exportExcel: false,
        advancedSearch: false,
      }
    },
    frequentlySearchedStatus: {
      type: String,
      default: null
    },
    notifyChildForMerchOrder: {
      type: Object,
      default: {
        reloadDataTable: false,
      },
    },
  },
  // emits 要在子層先註冊父層 @ 後面的名稱
  emits: ['advancedSearchData', 'profitString'],
  components: { DataTable, Column, ColumnGroup, Row, SpotWarehousing, ProgressBar,
    FieldFilter
  },
  data() {
    return {
      // modal
      advancedSearchModal: {},
      soldOutModal: {},
      setAllocatedAmountModal: {},
      delModal: {},
      editOrderModal: {},
      excelModal: {},
      introductionModal: {},
      orderNoteModal: {},
      warehousingModal: false,
      // components
      warehousingData: {
        warehousingModal: false,
        orderInfo: null,
      },
      // data
      currentPage: null,
      serverToken: '',
      storeId: 0,
      // dataTable
      scrollWidth: 0,
      scrollHeight: 0,
      dataTabelLoading: false,
      merchOrderList: [],
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        }
      },
      // 賣場顧客資訊 (為了合併帳號所呈現的資訊用)
      participantContainMergeInfo: null,
      // 進度條資料
      progressData: {
        target: 'merchOrder',
        percent: 0
      },
      // 選擇到的物件
      selectItems: [],
      // modal 內呈現選擇到的物件資訊
      propsSelectInfos: {
        show: 'id',
        data: []
      },
      // VIP
      storeVips: {
        loading: false,
        data: []
      },
      // 顧客群組
      storeCustomerGroups: {
        loading: false,
        data: []
      },
      // 供應商
      suppliers: {
        loading: false,
        data: []
      },
      // 斷貨
      canNotSoldOut: [],
      // 配單
      setAllocatedAmount: '',
      aAllocatedQuantity: 0,
      // 刪除
      canNotDel: [],
      // 訂單編輯
      merchOrderEdit: {
        quantity: 0,
        price: 0,
        totalPrice: 0,
        note: '',
      },
      // 訂單備註
      showOrderNote: '',
      // 進階搜尋
      advancedSearchData: {
        timeRange: {
          switch: true,
          startTime: null,
          endTime: null
        },
        DLtimeRange: {
          switch: false,
          startTime: null,
          endTime: null
        },
        merchandiseName: '',
        supplier: null,
        stylesName: '',
        searchOrderText: '',
        searchBuyerText: '',
        vipId: null,
        customerGroup: null,
        buyerNotify: null,
        singleSelect: {
          orderStatus: '未完成',
          allocatedStatus: '不選擇',
          checkoutStatus: '不選擇',
          paymentStatus: '不選擇',
          shippedStatus: '不選擇',
          buyerStatus: '不選擇',
          soldOutStatus: "不選擇"
        },
      },
      recordAdvancedSearchData: {},
      // 單選篩選陣列
      singleSelectsArray: [],
    }
  },
  created() {
    this.initialization()
    this.getPrefer()
    this.getStoreInfo()
  },
  mounted() {
    this.createModals([
      'soldOutModal','setAllocatedAmountModal','delModal','editOrderModal','excelModal','introductionModal',
      'advancedSearchModal', 'orderNoteModal'
    ])
    this.changeTableHeigth()
    // 監聽視窗異動
    window.addEventListener("resize", this.changeTableHeigth)
  },
  beforeUnmount() {
    // 移除監聽視窗異動
    window.removeEventListener("resize", this.changeTableHeigth)
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-merchOrder-local')
    this.$methods.breakOff([this._abortSortOutMerchOrders, this._countProfit])
  },
  watch: {
    "merchOrderEdit.quantity"() {
      this.merchOrderEdit.totalPrice = this.$methods.numberToFixed(this.merchOrderEdit.quantity * this.merchOrderEdit.price)
    },
    "merchOrderEdit.price"() {
      this.merchOrderEdit.totalPrice = this.$methods.numberToFixed(this.merchOrderEdit.quantity * this.merchOrderEdit.price)
    },
    showWhitchModal: {
      handler(val) {
        for (const [key, value] of Object.entries(val)) {
          if (key === 'setAllocatedAmount') {
            if (value[0]) return this.showModal(key, value[1])
          }
          else {
            if (value) return this.showModal(key)
          }
        }
      },
      deep: true
    },
    // 父層 > 常用搜尋
    frequentlySearchedStatus(val) {
      // 判斷是否有未處理到的項目，提醒工程師用
      if (!val) return alert('沒有此搜尋選項')
      this.frequentlySearched(val)
    },
    recordAdvancedSearchData: {
      handler(val) {
        this.$emit('advancedSearchData', val)
      },
      deep: true
    },
    'notifyChildForMerchOrder.reloadDataTable'(val) {
      if (val) {
        this.advancedSearch()
        this.notifyChildForMerchOrder.reloadDataTable = false
      }
    },
  },
  computed: {
    // 偏好 > 表格預設筆數
    D4Row() {
      let D4Row = 20
        this.$store.state.Prefer.p_merchOrder.rowCount.forEach(item => {
          if (item.selected) D4Row = item.value
        })
      return parseInt(D4Row)
    },
    // 偏好 > 進階搜尋預設時間區間的 range
    timeRange() {
      return parseInt(this.$store.state.Prefer.p_merchOrder.searchTimeRange[0].value)
    },
    // 偏好 > 表格欄位
    fieldFilter() {
      return this.$store.state.Prefer.p_merchOrder.fieldFilter
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      this.currentPage = this.$route.name
      // 介紹表格可點選區寬度
      this.scrollWidth = `max-width: ${window.innerWidth - 20}px`
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-merchOrder-local')
      // 紀錄進階搜尋單選條件
      this.singleSelectsArray = [
        {
          id: 'orderStatus',
          key: '訂單狀態',
          value: [
            {
              id: 'A1',
              value: '未完成'
            },
            {
              id: 'A2',
              value: '已完成'
            },
            {
              id: 'A3',
              value: '所有'
            },
            {
              id: 'A4',
              value: '訂單 ID'
            },
          ]
        },
        {
          id: 'allocatedStatus',
          key: '配單狀態',
          value: [
            {
              id: 'B1',
              value: '不選擇'
            },
            {
              id: 'B2',
              value: '配單數 = 0'
            },
            {
              id: 'B3',
              value: '部分配單'
            },
            {
              id: 'B4',
              value: '完全配單'
            },
            {
              id: 'B5',
              value: '配單數 > 0'
            },
          ]
        },
        {
          id: 'checkoutStatus',
          key: '結單狀態',
          value: [
            {
              id: 'C1',
              value: '不選擇'
            },
            {
              id: 'C2',
              value: '結單數 = 0'
            },
            {
              id: 'C3',
              value: '部分結單'
            },
            {
              id: 'C4',
              value: '完全結單'
            },
            {
              id: 'C5',
              value: '結單數 > 0'
            },
          ]
        },
        {
          id: 'paymentStatus',
          key: '收款狀態',
          value: [
            {
              id: 'D1',
              value: '不選擇'
            },
            {
              id: 'D2',
              value: '收款數 = 0'
            },
            {
              id: 'D3',
              value: '部分收款'
            },
            {
              id: 'D4',
              value: '完全收款'
            },
            {
              id: 'D5',
              value: '收款數 > 0'
            },
          ]
        },
        {
          id: 'shippedStatus',
          key: '寄出狀態',
          value: [
            {
              id: 'E1',
              value: '不選擇'
            },
            {
              id: 'E2',
              value: '寄出數 = 0'
            },
            {
              id: 'E3',
              value: '部分寄出'
            },
            {
              id: 'E4',
              value: '完全寄出'
            },
            {
              id: 'E5',
              value: '寄出數 > 0'
            },
          ]
        },
        {
          id: 'buyerStatus',
          key: '顧客資訊',
          value: [
            {
              id: 'F1',
              value: '不選擇'
            },
            {
              id: 'F2',
              value: '顧客 ID'
            },
            {
              id: 'F3',
              value: '名稱'
            },
            {
              id: 'F4',
              value: '暱稱'
            },
            {
              id: 'F5',
              value: 'FB 名稱'
            },
            {
              id: 'F6',
              value: 'Line 名稱'
            },
            {
              id: 'F7',
              value: '顧客群組'
            },
            {
              id: 'F8',
              value: '顧客 VIP'
            },
            {
              id: 'F9',
              value: '顧客 Notify'
            },
          ]
        },
        {
          id: 'soldOutStatus',
          key: '斷貨狀態',
          value: [
            {
              id: 'G1',
              value: '不選擇'
            },
            {
              id: 'G2',
              value: '已斷貨'
            },
            {
              id: 'G3',
              value: '部分斷貨'
            },
            {
              id: 'G4',
              value: '完全斷貨'
            },
            {
              id: 'G5',
              value: '未斷貨'
            },
          ]
        },
      ]
      // 多紀錄進階搜尋
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    // dataTable 內容高度計算
    changeTableHeigth() {
      // 紀錄 dataTabel 高度 (40px: 最上面 marginTop, 170px: 表格中除了內容其餘多的)
      switch (this.currentPage) {
        case 'MerchOrder':
          this.scrollHeight = window.innerHeight - document.getElementById('MerchOrderTopArea').clientHeight - 160
          break;
        case 'Orders':
          this.scrollHeight = window.innerHeight - 210
          break;
        case 'P_MerchOrder':
          this.scrollHeight = window.innerHeight - 210
          break;
        default:
          throw new Error('MerchOrderTable.vue > changeTableHeigth > currentPage 未處理')
      }
    },
    // 開啟 modal
    showModal(status, item) {
      if (status === 'soldOut') {
        // 斷貨
        this.showWhitchModal.soldOut = false
        // 批次操作
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆訂單')
        this.propsSelectInfos.data = this.selectItems
        this.canNotSoldOut = []
        this.selectItems.forEach((item) => {
          if (item.soldOutQuantity > 0) {
            this.canNotSoldOut.push(item.id)
          }
        })
        this.soldOutModal.show()
      } else if (status === 'setAllocatedAmount') {
        // 配單
        this.showWhitchModal.setAllocatedAmount = false
        // 配單 (全數配單)
        if (item === 'max') {
          if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆訂單')
          this.setAllocatedAmount = '配單 (全數配單)'
          this.propsSelectInfos.data = this.selectItems
        }
        // 配單 (初始化)
        else if (item === 'min') {
          if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆訂單')
          this.setAllocatedAmount = '配單 (初始化)'
          this.propsSelectInfos.data = this.selectItems
        }
        // 配單 (單項)
        else {
          this.setAllocatedAmount = '配單 (單項)'
          this.aAllocatedQuantity = item.quantity // modal 上配單 input 欄位需要
          // 多取可配數
          this.$api.merchOrder.getOtherInfo([item.id], "{getStock{getAvailableFromStockQuantity}}").then(res => {
            console.log(res)
            if (res.code === "200") {
              const merchOrder = res.data[0].objects[0]
              const copyItem = JSON.parse(JSON.stringify(this.selectItems[0]))
              copyItem.availableFromStockQuantity = merchOrder.stock.availableFromStockQuantity
              this.selectItems[0] = copyItem
            }
          }).catch(err => {
            console.log(err)
            this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
            this.$methods.switchLoading('hide')
          })
          this.getSelectInfo(item)
        }
        this.setAllocatedAmountModal.show()
      } else if (status === 'del') {
        // 刪除
        this.showWhitchModal.del = false
        if (item) this.getSelectInfo(item)
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆訂單')
        this.propsSelectInfos.data = this.selectItems
        this.canNotDel = [],
        this.selectItems.forEach((item) => {
          if (item.allocatedQuantity > 0 || item.checkOutQuantity > 0) {
            this.canNotDel.push(item.id)
          }
        })
        this.delModal.show()
      } else if (status === 'editOrder') {
        // 編輯訂單
          this.showWhitchModal.editOrder = false
          this.getSelectInfo(item)
          this.merchOrderEdit = {
            quantity: this.selectItems[0].quantity,
            price: this.selectItems[0].price,
            totalPrice: this.selectItems[0].totalPrice,
            note: this.selectItems[0].note,
          }
          this.editOrderModal.show()
      } else if (status === 'exportExcel') {
        // 匯出 Excel
          this.showWhitchModal.exportExcel = false
          if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆訂單')
          this.propsSelectInfos.data = this.selectItems
          this.excelModal.show();  
      } else if (status === 'orderNote') {
        // 顯示訂單備註
        this.showOrderNote = item
        this.orderNoteModal.show()
      } else if (status === 'warehousing') {
        // 現貨入庫
        this.selectItems = []
        this.selectItems.push(JSON.parse(JSON.stringify(item)))
        // 多取可配數
        this.$api.merchOrder.getOtherInfo([item.id], "{getStock{getAvailableFromStockQuantity}}").then(res => {
          console.log(res)
          if (res.code === "200") {
            const merchOrder = res.data[0].objects[0]
            this.selectItems[0].stock = merchOrder.stock
            this.warehousingData.orderInfo = JSON.parse(JSON.stringify(this.selectItems[0]))
            this.warehousingData.warehousingModal = true
          }
        }).catch(err => {
          console.log(err)
          this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          this.$methods.switchLoading('hide')
        })
      } else if (status === 'advancedSearch') {
        // 進階搜尋
        this.showWhitchModal.advancedSearch = false
        this.advancedSearchData = JSON.parse(JSON.stringify(this.recordAdvancedSearchData))
        this.advancedSearchModal.show()
      }
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // todo (整合api) 取得賣場資訊
    getStoreInfo() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getSuppliers,getStoreVips,getCustomerGroups}'
        }
      ]
      this.storeVips = {
        loading: true,
        data: []
      }
      this.storeCustomerGroups = {
        loading: true,
        data: []
      }
      this.suppliers = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.storeInfo = storeInfo
            vm.storeVips = {
              loading: false,
              data: storeInfo.storeVips
            }
            vm.storeCustomerGroups = {
              loading: false,
              data: storeInfo.customerGroups
            }
            // 供應商
            vm.suppliers = {
              loading: false,
              data: storeInfo.suppliers
            }
            // 先取得預設時間區間
            vm.computedD4TimeRange() 
            // 如果是從 "庫存管理" / "對帳出貨" 連結過來，就不做
            if (vm.$route.query && vm.$route.query.search) {
              vm.advancedSearchData.timeRange.switch = false
              vm.advancedSearchData.singleSelect.orderStatus = '所有'
            }
            vm.getStoreParticipantContainMergeInfo()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 計算預設時間區間
    computedD4TimeRange() {
      this.advancedSearchData.timeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.timeRange.startTime = this.$methods.moment().subtract(this.timeRange, 'days').format('YYYY-MM-DD')
    },
    // * 更新資料 (start)
    /**
     * OK 更新資料
     * @updateItem 要更新的物件
     * @modal 需要關閉的 modal
     */
    updateData(updateItem, modal) {
      this.$methods.switchLoading('show')
      let searchData = {
        storeId: this.storeId,
        merchOrderIds: [],
      }
      this.selectItems.forEach(merchorder => searchData.merchOrderIds.push(merchorder.id))
      this.$api.merchOrder.getDataTable(searchData).then(res => {
        console.log(res);
        if (res.code === '200') {
          const merchorders = res.data
          this.sortoutUpdateData(merchorders, updateItem, modal)
        }
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // Ok 整理更新資料
    sortoutUpdateData(merchorders, updateItem, modal) {
      this.merchOrderList.forEach((originMerhOrder, index, arr) => {
        merchorders.forEach(updateMerhOrder => {
          const newMerchOrder = this.handleMerchOrderObject(updateMerhOrder)
          if (originMerhOrder.id === newMerchOrder.id) {
            switch (updateItem) {
              case 'normal':
                arr[index] = JSON.parse(JSON.stringify(newMerchOrder))
                break;
              default:
                throw new Error('Merchorder.vue > sortoutUpdateData > updateItem 未處理')
            }
          }
        })
      })
      if (modal !== 'soldOutModal' && modal !== 'setAllocatedAmountModal') this.SweetAlert('200')
      if (modal) {
        this[modal].hide()
        this.selectItems = []
      }
      this.$methods.switchLoading('hide')
    },
    // * 更新資料 (end)
    // * 取資料 (start)
    // 常用搜尋
    frequentlySearched(searchStatus) {
      // 紀錄要變更的單選項目
      let singleSelectOption = null
      // 還原所有單選選項預設值
      this.advancedSearchData.singleSelect.allocatedStatus = '不選擇'
      this.advancedSearchData.singleSelect.checkoutStatus = '不選擇'
      // 判斷是哪個單選項目要變更
      if (searchStatus === '配單數 = 0' || searchStatus === '部分配單' || searchStatus === '完全配單' || searchStatus === '配單數 > 0') singleSelectOption = 'allocatedStatus'
      else if (searchStatus === '結單數 = 0' || searchStatus === '部分結單' || searchStatus === '完全結單' || searchStatus === '結單數 > 0') singleSelectOption = 'checkoutStatus'
      // 判斷是否有未處理到的項目，提醒工程師用
      if (!singleSelectOption) return alert('沒有此搜尋選項')
      this.advancedSearchData.singleSelect[singleSelectOption] = searchStatus
      this.advancedSearch()
    },
    // OK 取賣場顧客所有有關合併帳號資料
    getStoreParticipantContainMergeInfo() {
      this.participantContainMergeInfo = new Map()
      this.$api.participant.getStoreParticipantContainMergeInfo({storeId: this.storeId}).then(res => {
        console.log(res)
        if (res.code === "200") {
          const participantContainMergeInfo = res.data
          // 紀錄使用者綁定社群資料
          participantContainMergeInfo.forEach(item => {
            this.participantContainMergeInfo.set(item.participantId, {
              name: item.name,
              physicalUserId: item.physicalUserId,
              mergePhysicaluserId: item.mergePhysicaluserId,
              isMaster: item.isMaster,
              afterMergeFbName: item.afterMergeFbName ? item.afterMergeFbName : null,
              afterMergeFbPicture: item.afterMergeFbPicture ? item.afterMergeFbPicture : null,
              afterMergeLineName: item.afterMergeLineName ? item.afterMergeLineName : null,
              afterMergeLinePicture: item.afterMergeLinePicture ? item.afterMergeLinePicture : null,
              afterMergeBindingLineNotify: item.bindingLineNotify ? item.bindingLineNotify : null
            })
          })
          this.advancedSearch()
        }
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // OK 進階搜尋
    advancedSearch(closeModal) {
      if (this.advancedSearchData.singleSelect.orderStatus === '訂單 ID' && !this.advancedSearchData.searchOrderText) return this.SweetAlert('other', '請輸入搜尋內容')
      if (this.advancedSearchData.singleSelect.buyerStatus !== '不選擇' && this.advancedSearchData.singleSelect.buyerStatus !== '顧客群組' && this.advancedSearchData.singleSelect.buyerStatus !== '顧客 VIP' && this.advancedSearchData.singleSelect.buyerStatus !== '顧客 Notify' && !this.advancedSearchData.searchBuyerText) return this.SweetAlert('other', '請輸入搜尋內容')
      // 先中止 call API 跟同步動作
      this.$methods.breakOff([this._abortSortOutMerchOrders, this._countProfit])
      // 執行進階搜尋步驟
      this.dataTabelLoading = true
      this.$methods.switchLoading('show')
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
      let searchData = {
        storeId: this.storeId,
      }
      // (不能加預設搜尋條件) 如果是從 "庫存管理" / "對帳出貨" 連結過來
      if (this.$route.query.search) {
        searchData.merchOrderIds = this.$route.query.search.split(',')
        let newQuery = JSON.parse(JSON.stringify(this.$route.query))
        delete newQuery.search
        this.$router.replace({query: newQuery})
      } else {
        // 如果是從商品管理過來
        if (this.$route.query.merchId) {
          searchData.merchandiseIds = [this.$route.query.merchId]
          let newQuery = JSON.parse(JSON.stringify(this.$route.query))
          delete newQuery.merchId
          this.$router.replace({query: newQuery})
        }
        // 如果是顧客 > 訂單管理
        if (this.$route.name === 'P_MerchOrder') {
          searchData.participantIds = [this.$route.params.participantId]    
        }
        // 如果是商品詳細資訊
        if (this.$route.name === 'Orders') {
          searchData.merchandiseIds = [this.$route.query.merchandiseId]
        }
        // 時間區間
        if (this.advancedSearchData.timeRange.switch) {
          if (this.advancedSearchData.timeRange.startTime) {
            const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.startTime} 00:00:00`) / 1000
            searchData.createTimeStart = startTime
          }
          if (this.advancedSearchData.timeRange.endTime) {
            const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.endTime} 23:59:59`) / 1000
            searchData.createTimeEnd = endTime
          }
        }
        // 商品收單區間
        if (this.advancedSearchData.DLtimeRange.switch) {
          if (this.advancedSearchData.DLtimeRange.startTime) {
            const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.DLtimeRange.startTime} 00:00:00`) / 1000
            searchData.deadLineTimeStart = startTime
          }
          if (this.advancedSearchData.DLtimeRange.endTime) {
            const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.DLtimeRange.endTime} 23:59:59`) / 1000
            searchData.deadLineTimeEnd = endTime
          }
        }
        // 商品名稱
        if (this.advancedSearchData.merchandiseName) {
          searchData.name = this.advancedSearchData.merchandiseName
        }
        // 供應商
        if (this.advancedSearchData.supplier) {
          searchData.supplierId = this.advancedSearchData.supplier.id
        }
        // 款式
        if (this.advancedSearchData.stylesName) {
          searchData.merchandiseStyleName = this.advancedSearchData.stylesName
        }
        // 訂單狀態
        switch (this.advancedSearchData.singleSelect.orderStatus) {
          case '訂單 ID':
            searchData.merchOrderIds = this.advancedSearchData.searchOrderText.split(",")
            break;
          case '未完成':
            searchData.orderStatus = "unfinished"
            break;
          case '已完成':
            searchData.orderStatus = "completed"
            break;
        }
        // 配單狀態
        switch (this.advancedSearchData.singleSelect.allocatedStatus) {
          case '配單數 = 0':
            searchData.allocatedStatus = "equalToZero"
            break;
          case '部分配單':
            searchData.allocatedStatus = "part"
            break;
          case '完全配單':
            searchData.allocatedStatus = "all"
            break;
          case '配單數 > 0':
            searchData.allocatedStatus = "greaterThanZero"
            break;
        }
        // 結單狀態
        switch (this.advancedSearchData.singleSelect.checkoutStatus) {
          case '結單數 = 0':
            searchData.checkoutStatus = "equalToZero"
            break;
          case '部分結單':
            searchData.checkoutStatus = "part"
            break;
          case '完全結單':
            searchData.checkoutStatus = "all"
            break;
          case '結單數 > 0':
            searchData.checkoutStatus = "greaterThanZero"
            break;
        }
        // 收款狀態
        switch (this.advancedSearchData.singleSelect.paymentStatus) {
          case '收款數 = 0':
            searchData.paymentStatus = "equalToZero"
            break;
          case '部分收款':
            searchData.paymentStatus = "part"
            break;
          case '完全收款':
            searchData.paymentStatus = "all"
            break;
          case '收款數 > 0':
            searchData.paymentStatus = "greaterThanZero"
            break;
        }
        // 寄出狀態
        switch (this.advancedSearchData.singleSelect.shippedStatus) {
          case '寄出數 = 0':
            searchData.shippedStatus = "equalToZero"
            break;
          case '部分寄出':
            searchData.shippedStatus = "part"
            break;
          case '完全寄出':
            searchData.shippedStatus = "all"
            break;
          case '寄出數 > 0':
            searchData.shippedStatus = "greaterThanZero"
            break;
        }
        // 顧客資訊
        switch (this.advancedSearchData.singleSelect.buyerStatus) {
          case '顧客 ID':
            searchData.participantIds = this.advancedSearchData.searchBuyerText.split(",")
            break;
          case '名稱':
            searchData.buyerName = this.advancedSearchData.searchBuyerText
            break;
          case '暱稱':
            searchData.buyerNickName = this.advancedSearchData.searchBuyerText
            break;
          case 'FB 名稱':
            searchData.buyerFbName  = this.advancedSearchData.searchBuyerText
            break;
          case 'Line 名稱':
            searchData.buyerLineName = this.advancedSearchData.searchBuyerText
            break;
          case '顧客群組':
            searchData.buyerGroupId = this.advancedSearchData.customerGroup
            break;
          case '顧客 VIP':
            searchData.buyerVipId = this.advancedSearchData.vipId
            break;
          case '顧客 Notify':
            searchData.buyerNotify = this.advancedSearchData.buyerNotify
            break;
        }
      }
      if (closeModal) this.advancedSearchModal.hide()

      this.$api.merchOrder.getDataTable(searchData).then(res => {
        console.log(res)
        if (res.code === '200') {
          const merchOrderList = res.data
          this.merchOrderList = []
          this.sortOutMerchOrder(merchOrderList)
        }
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
        this.dataTabelLoading = false
      })
    },
    // OK 整理訂單列表
    sortOutMerchOrder(merchOrderList) {
      merchOrderList.forEach(item => {
        const object = this.handleMerchOrderObject(item)

        // 進階搜尋 > 顧客資訊 (只有 line 跟 fb 因為有合併顧客問題)
        let matchBuyerStatus = false
        switch (this.advancedSearchData.singleSelect.buyerStatus) {
          case 'FB 名稱':
            if (object.buyer.fbName && object.buyer.fbName.match(this.advancedSearchData.searchBuyerText)) matchBuyerStatus = true
            break;
          case 'Line 名稱':
            if (object.buyer.lineName && object.buyer.lineName.match(this.advancedSearchData.searchBuyerText)) matchBuyerStatus = true
            break;
          case '顧客 Notify':
            if (this.advancedSearchData.buyerNotify !== null) {
              if (object.buyer.buyerHasNotify && this.advancedSearchData.buyerNotify) matchBuyerStatus = true
              else if (!object.buyer.buyerHasNotify && !this.advancedSearchData.buyerNotify) matchBuyerStatus = true
            } else matchBuyerStatus = true
            break;
          default:
            matchBuyerStatus = true
            break;
        }

        // 進階搜尋 > 斷貨狀態
        let soldOutStatus = false
        switch (this.advancedSearchData.singleSelect.soldOutStatus) {
          case '已斷貨':
            if (object.soldOutQuantity > 0) soldOutStatus = true
            break;
          case '部分斷貨':
            if (object.soldOutQuantity > 0 && object.quantity > 0) soldOutStatus = true
            break;
          case '完全斷貨':
            if (object.soldOutQuantity > 0 && object.quantity === 0) soldOutStatus = true
            break;
          case '未斷貨':
            if (object.soldOutQuantity === 0) soldOutStatus = true
            break;
          default:
            soldOutStatus = true
            break;
        }

        if (matchBuyerStatus && soldOutStatus) this.merchOrderList.push(JSON.parse(JSON.stringify(object)))
      })
      console.log(this.merchOrderList)
      this.dataTabelLoading = false
      this.countProfit()
      this.$methods.switchLoading('hide')
    },
    // OK 特別處理訂單物件
    handleMerchOrderObject(item) {
      const object = {
        id: item.merchOrderId,
        creationType: item.creationType,
        note: item.merchOrderNote ? item.merchOrderNote : null,
        quantity: item.quantity,
        price: item.price,
        totalPrice: this.$methods.numberToFixed(item.price * item.quantity),
        soldOutQuantity: item.soldOutQuantity,
        allocatedQuantity: item.allocatedQuantity,
        checkOutQuantity: item.checkOutQuantity,
        paymentQuantity: item.paymentQuantity,
        shippingQuantity: item.shippingQuantity,
        profit: this.$methods.numberToFixed((item.price - item.styleCost) * item.quantity),
        createTime: this.$methods.moment(item.createTime).format("YYYY-MM-DD HH:mm:ss"),
        merchandise: {
          id: item.merchandiseId,
          frontImage: item.frontImage ? item.frontImage : null,
          name: item.merchandiseName,
          note: item.merchandiseNote ? item.merchandiseNote : null,
          deleted: item.merchandiseDeleted,
          style: item.merchandiseStyleName,
          supplierName: item.supplierName ? item.supplierName : null,
          styleCost: item.styleCost
        },
        buyer: {
          id: item.buyerId,
          name: item.buyerName,
          nickName: item.buyerNickName ? item.buyerNickName : null,
          fbName: item.buyerFbName ? item.buyerFbName : null,
          lineName: item.buyerLineName ? item.buyerLineName : null,
          buyerHasNotify: item.buyerHasNotify ? item.buyerHasNotify : null,
          storeVipName: item.buyerStoreVipName ? item.buyerStoreVipName : null,
          customerGroupName: item.buyerCustomerGroupName ? item.buyerCustomerGroupName : null,
          physicalUserId: item.physicalUserId ? item.physicalUserId : null,
          masterId: item.masterId ? item.masterId : null
        }
      }
      // 處理合併帳號顧客
      const mergeInfo = this.participantContainMergeInfo.get(object.buyer.id)
      if (mergeInfo) {
        object.buyer.fbName = object.buyer.fbName ? object.buyer.fbName : mergeInfo.afterMergeFbName
        object.buyer.lineName = object.buyer.lineName ? object.buyer.lineName : mergeInfo.afterMergeLineName
      }

      return object
    },
    // OK 計算獲利
    countProfit() {
      this._countProfit = setTimeout(() => {
        let profitString = null
        let profit = {
          profitMoney: 0,
          totalMoney: 0,
          totalCost: 0,
          hasCostQuantity: 0,
          orderQuantity: this.merchOrderList.length,
          profitPercent: 0
        }
        this.merchOrderList.forEach(item => {
          profit.totalMoney += item.quantity * this.$methods.numberToFixed(item.price)
          profit.profitMoney += this.$methods.numberToFixed(item.profit)
          if (item.merchandise.styleCost > 0) {
            profit.hasCostQuantity ++
            profit.totalCost += item.merchandise.styleCost
          }
        })
        // 計算獲利 % 數
        profit.profitPercent = this.$methods.numberToFixed((profit.profitMoney / profit.totalMoney) * 100)
        if (profit.hasCostQuantity > 0) profitString = `共${profit.orderQuantity}筆訂單，總計: ${this.$methods.numberToFixed(profit.totalMoney)}元，其中有${profit.hasCostQuantity}筆輸入成本，總獲利${this.$methods.numberToFixed(profit.profitMoney)}元(${this.$methods.numberToFixed(profit.profitPercent)}%)`
        else profitString = '沒有足夠的成本資訊 !'
        this.$emit('profitString', profitString)
      }, 2)
    },
    // * 取資料 (end)
    getSelectInfo(item) {
      // 準備 modal 內選擇幾筆資訊
      this.selectItems = []
      this.propsSelectInfos.data = []
      this.selectItems.push(item)
      this.propsSelectInfos.data = this.selectItems
      console.log('選擇欄位:',this.selectItems)
    },
    // OK 斷貨
    soldOut() {
      this.$methods.switchLoading('show')
      let merchOrderIds = []
      this.selectItems.forEach((item) => {
        if (item.soldOutQuantity === 0) {
          merchOrderIds.push(item.id)
        }
      })
      this.$api.merchOrder.setSoldOut(merchOrderIds).then(res => {
        console.log(res);
        if (res.code === '200') {
          if (this.canNotSoldOut.length > 0) {
            // 選擇到不能被斷貨資料 (已斷貨)
            let value = '訂單編號：'
            value += this.canNotSoldOut.join('、')
            value += ' 未被斷貨<br />(原因：訂單已被斷貨)'
            let message = {
              icon: merchOrderIds === '' ? 'warning': 'success',
              title: {
                show: true,
                value: merchOrderIds === '' ? '' : '部份成功',
              },
              text: {
                show: true,
                value: value
              },
              timer: 10000
            }
            this.SweetAlert('setByMyself', message)
          } 
        } else if (res.code === '206') {
          const errorMap = new Map()
          res.errorData.forEach(item => {
            if (!errorMap.has(item.code)) {
              errorMap.set(item.code, {
                message: item.message,
                data: item.errorData
              })
            }
          })
          let retrunObject = {
            icon: 'warning',
            title: {
              show: true,
              value: '部份成功'
            },
            text: {
              show: true,
              value: ''
            },
            timer: false,
            showConfirmButton: true,
          }
          for (const [key, value] of errorMap) {
            retrunObject.text.value += `${value.message}，訂單ID為 `
            value.data.forEach(item => {
              retrunObject.text.value += `${item.id} `
            })
            retrunObject.text.value += '\n'
          }
          console.log(retrunObject)
          this.SweetAlert('setByMyself', retrunObject)
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.updateData('normal', 'soldOutModal')
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // OK 調整訂單配單數量 (配單、全數配單、配單初始化)
    setAllocatedQuantity() {
      this.$methods.switchLoading('show')
      let data = [];
      this.selectItems.forEach((item) => {
        let obj = {}
        obj.id = item.id
        if (this.setAllocatedAmount === '配單 (全數配單)') obj.allocatedQuantity = "max"
        else if (this.setAllocatedAmount === '配單 (初始化)') obj.allocatedQuantity = "min"
        else if (this.setAllocatedAmount === '配單 (單項)') obj.allocatedQuantity = this.aAllocatedQuantity !== '' ? this.aAllocatedQuantity : 0 
        if (this.setAllocatedAmount !== '配單 (單項)' && item.quantity === 0) console.log(item.id,' 數量小於 0')
        else data.push(obj)
      })
      console.log("配單:", data)
      this.$api.merchOrder.setAllocatedQuantity(data).then(res => {
        console.log(res);
        if (res.code === '200') {
          this.SweetAlert(res.code, res.message)
          this.updateData('normal', 'setAllocatedAmountModal')
          this.setAllocatedAmount = ''
        } else {
          let value = ''
          let state = ''
          res.errorData.forEach((item) => {
            value += '<span class="fw-bolder">訂單編號：</span>'
            let idArr = []
            item.errorData.forEach((item) => {
              idArr.push(item.id)
            })
            state = this.selectItems.length === idArr.length ? 'warning' : 'success',
            value += idArr.join('、')
            value += '<br />' + item.message
          })
          console.log(value)
          let message = {
            icon: state,
            title: {
              show: true,
              value: state === 'warning' ? '' : '部分成功'
            },
            text: {
              show: true,
              value: value
            },
            timer: false,
            showConfirmButton: true,
          }
          if (state === 'success') this.updateData('normal', 'setAllocatedAmountModal')
          this.SweetAlert('setByMyself', message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // OK 刪除
    del() {
      let delArr = []
      this.selectItems.forEach((item) => {
        if (item.allocatedQuantity === 0 && item.checkOutQuantity === 0) {
          delArr.push(item.id)
        }
      })
      if (delArr.length === 0) return this.SweetAlert('other', '您所選擇的訂單皆不能被刪除')
      this.$methods.switchLoading('show')
      this.$api.merchOrder.deleted(delArr).then(res => {
        console.log(res)
        if (res.code === '200') {
          // 刪除表格資料
          this.SweetAlert(res.code, res.message)
          let newMerchOrderList = this.merchOrderList.filter((item) => !delArr.includes(item.id))
          this.merchOrderList = newMerchOrderList
          this.selectItems = []
          this.delModal.hide()
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // OK 更新訂單 (編輯)
    updateOrder() {
      this.$methods.switchLoading('show')
      const data = [{
        id: this.selectItems[0].id,
        price: this.merchOrderEdit.price ? this.merchOrderEdit.price : 0,
        quantity: this.merchOrderEdit.quantity ? this.merchOrderEdit.quantity : 0,
        note: this.merchOrderEdit.note,
      }]
      this.$api.merchOrder.update(data).then(res => {
        console.log(res)
        if (res.code === '200') {
          this.updateData("normal", "editOrderModal")
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // OK 匯出 Excel 
    exportExcel() {
      const token = this.serverToken;
      let exportExcelArr = []
      this.selectItems.forEach((item) => {
        exportExcelArr.push(item.id)
      })
      const ids = exportExcelArr.toString()
      const exportExportUrl = `${process.env.VUE_APP_API}/download/exportDataTableExcel?authorization=${token}&siteObjectTypeIndex=26&ids=${ids}`;
      window.open(exportExportUrl, "_blank");
      this.selectItems = []
      this.excelModal.hide()
    },
    // OK 來自現貨入庫的通知 
    notifyFromSpotWarehousing (object) {
      // 這個不能做單獨資料更新，若是入庫並配單，我是不知道有哪些訂單是這次配單的
      if (object.reload) this.advancedSearch()
    },
  },
}
</script>

<style lang="scss" scoped>
.ellipsis2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /*行數*/
  -webkit-box-orient: vertical;
  word-break: break-all; /*針對英文 */
}
</style>